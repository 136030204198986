import Vue from 'vue'
import https from '@/libs/axios'

export default {

  install() {
    /** Login Start * */

    Vue.prototype.UserLogin = function (params) {
      return https.post('user/login', params)
    }

    Vue.prototype.UserData = function () {
      return https.get('user/my')
    }

    Vue.prototype.UserUpdate = function (params) {
      return https.put('user/update', params)
    }

    Vue.prototype.UserDorm = function () {
      return https.get('user/dorm')
    }

    Vue.prototype.UploadFile = function (formData) {
      return https.post('user/upload/file', formData)
    }

    /** Login End * */



    /** awards start */
    Vue.prototype.awardsAppraise = function (data) {
      return https.post('awards/appraise/save', data)
    }

    Vue.prototype.AwardsStudentLists = function (params) {
      return https.get('awards/appraise/student', { params })
    }



    Vue.prototype.AwardsLists = function (params) {
      return https.get('awards/lists', { params })
    }

    Vue.prototype.AwardsGet = function (params) {
      return https.get('awards/get', { params })
    }

    Vue.prototype.AwardsVoteLists = function (params) {
      return https.get('awards/vote/lists', { params })
    }

    Vue.prototype.AwardsVoteSave = function (data) {
      return https.post('awards/vote/save', data)
    }

    Vue.prototype.AwardsRequestGet = function (params) {
      return https.get('awards/request/get', { params })
    }

    Vue.prototype.AwardsAppraiseCountLv = function (params) {
      return https.get('awards/appraise/count/lv', { params })
    }

    /** awards end */



  },
}
