import Vue from 'vue'
// import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
// import VueCompositionAPI from '@vue/composition-api'

import variable from '@/common/variable'
import funs from '@/common/fun'
import apis from '@/common/api'

import page from '@/layouts/components/Page.vue'

// import PubSub from 'pubsub-js'
import Print from '/src/service/print.js'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
// import '@/libs/portal-vue'
import '@/libs/toastification'
import '@axios'
// import '@/libs/clipboard'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
// import '@/libs/tour'
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.component('page', page)
Vue.config.productionTip = false
Vue.config.performance = true
Vue.prototype.$variable = variable
//
// Vue.prototype.PubSub = PubSub
//
// Vue.use(ToastPlugin)
// Vue.use(ModalPlugin)
Vue.use(apis)
Vue.use(funs)
Vue.use(Print)
// Composition API
// Vue.use(VueCompositionAPI)
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
