export default {
  apiAddr: 'https://api.teacher.imrschool.com/teacher/v1/', // https://api.teacher.imrschool.com/teacher/v1/    http://127.0.0.1:9005/teacher/v1/'

  orientation: [
    { name: '东', id: 1 },
    { name: '南', id: 2 },
    { name: '西', id: 3 },
    { name: '北', id: 4 },
  ],
  dormSex: [
    { name: '男', id: 1 },
    { name: '女', id: 2 },
    { name: '夫妻', id: 3 },
  ],
  sex: [
    { name: '男', id: 1 }, { name: '女', id: 2 },
  ],
  teacherRoll: [
    { name: '博士导师', id: 1 },
    { name: '硕士导师', id: 2 },
    { name: '管理人员', id: 3 },
  ],
  duration: [
    { name: '三年', id: 1 }, { name: '五年', id: 2 },
  ],
  year: [2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032],
  month: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],


  toolbarOptions: [
    ['bold', 'italic', 'underline', 'strike'], // 加粗，斜体，下划线，删除线
    ['blockquote', 'code-block'], // 引用，代码块
    [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
    [{ list: 'ordered' }, { list: 'bullet' }], // 列表
    [{ script: 'sub' }, { script: 'super' }], // 上下标
    [{ indent: '-1' }, { indent: '+1' }], // 缩进
    [{ direction: 'rtl' }], // 文本方向
    [{ size: ['12px', '14px', '16px', '18px', '20px', '22px', '24px', '28px', '32px', '36px'] }], // 字体大小
    [{ header: [1, 2, 3, 4, 5, 6, false] }], // 几级标题
    [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
    [{ font: [] }], // 字体
    [{ align: [] }], // 对齐方式
    ['clean'], // 清除字体样式
    ['image', 'upload'], // 链接、图片、视频
    // ['sourceEditor']
  ],

}
