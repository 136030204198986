<template>
  <div>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">显示 1 到 {{page.pageSize}} 行数据 , 共计 {{ page.totalRows }} 条数据</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="page.totalRows"
            :per-page="page.pageSize"
            first-number
            last-number

            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BPagination,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BPagination,
  },
  props: ['page'],
  data() {
    return {
      perPage: 10,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
    }
  },
  watch: {
    currentPage(val) {
      this.$emit('getPage', val)
    },

  },
}
</script>
