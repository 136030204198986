import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {

  install() {
    Vue.prototype.GetSex = function (val) {
      let sex = '未知'
      switch (val) {
        case 0:
          sex = '未知'
          break
        case 1:
          sex = '男'
          break
        case 2:
          sex = '女'
          break
        default:
          sex = '未知'
      }
      return sex
    }
    Vue.prototype.GetTeacherRole = function (val) {
      let name = '未知'
      switch (val) {
        case 1:
          name = '博士生导师'
          break
        case 2:
          name = '硕士生导师'
          break
        case 3:
          name = '管理人员'
          break
        default:
          name = '未知'
      }
      return name
    }
    Vue.prototype.isMobile= function () {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      return flag;
    },

    Vue.prototype.dateToYmd = function (time) {
      const d = time ? new Date(time) : new Date()
      const year = d.getFullYear()
      let month = d.getMonth() + 1
      let day = d.getDate()
      let hours = d.getHours()
      let min = d.getMinutes()
      let seconds = d.getSeconds()

      if (month < 10) month = `0${month}`
      if (day < 10) day = `0${day}`
      if (hours < 0) hours = `0${hours}`
      if (min < 10) min = `0${min}`
      if (seconds < 10) seconds = `0${seconds}`

      return (`${year}-${month}-${day}`)
    }

    Vue.prototype.IfSexToSelect = function (val) {
      let sex = '未知'
      switch (val) {
        case 1:
          sex = { name: '男', id: 1 }
          break
        case 2:
          sex = { name: '女', id: 2 }
          break
        default:
          sex = { name: '未知', id: 0 }
      }
      return sex
    }

    Vue.prototype.DormWhere = function (val) {
      let d = val
      if (val === 0 || val === '') {
        d = '不限'
      }
      return d
    }

    Vue.prototype.DormSex = function (val) {
      let sex = '未知'
      switch (val) {
        case 1:
          sex = { name: '男', id: 1 }
          break
        case 2:
          sex = { name: '女', id: 2 }
          break
        default:
          sex = { name: '夫妻', id: 3 }
      }
      return sex
    }

    Vue.prototype.DormBunkStatus = function (val) {
      let name = '未知'
      switch (val) {
        case 0:
          name = '空'
          break
        case 1:
          name = '预定'
          break
        case 2:
          name = '入住'
          break
        case 3:
          name = '管理员安排'
          break
        default:
          name = '位置'
      }
      return name
    }

    Vue.prototype.DormOrientation = function (val) {
      let orientation = '未知'
      // eslint-disable-next-line default-case
      switch (val) {
        case 1:
          orientation = { name: '东', id: 1 }
          break
        case 2:
          orientation = { name: '南', id: 2 }
          break
        case 3:
          orientation = { name: '西', id: 3 }
          break
        case 4:
          orientation = { name: '北', id: 4 }
          break
      }
      return orientation
    }

    Vue.prototype.ReloadPage = function () {
      this.$router.go(0)
    }
    Vue.prototype.StudentCultivateColor = function (val) {
      let value = ''
      switch (val) {
        case "博士研究生":
          value = { value: '博士', variant: 'success' }
          break
        case "直博生":
          value = { value: '直博', variant: 'success' }
          break
        case "硕士研究生":
          value = { value: '硕士', variant: 'info' }
          break
        default:
          value = { value: val, variant: 'danger' }
      }
      return value
    }

    Vue.prototype.StudentWhetherGetColor = function (val) {
      let value = ''
      switch (val) {
        case "":
          value = { value: '未曾获得', variant: 'info' }
          break
        case "0":
          value = { value: '未曾获得', variant: 'info' }
          break
        default:
          value = { value: val, variant: 'success' }
      }
      return value
    }
    Vue.prototype.showToast = function (status, msg, time = 10000) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: '反馈通知',
          icon: 'BellIcon',
          text: msg,
          variant: status === 0 || status === 200 ? 'success' : 'danger',
        },
      }, {
        timeout: time,
      })
    }

    Vue.prototype.GetStudentStatus = function (val) {
      let value = '未知'
      switch (val) {
        case '在学':
          value = { value: val, variant: 'success' }
          break
        case '在学':
          value = { value: val, variant: 'primary' }
          break
        case '在学':
          value = { value: val, variant: 'warning' }
          break
        default:
          value = { value: val, variant: 'danger' }
          break
      }
      return value
    }

    Vue.prototype.GetSourceName = function (val) {
      return this.$variable.source.filter(item => item.value === val)[0].label
    }

    Vue.prototype.GetSourceArray = function (val) {
      return this.$variable.source.filter(item => item.value === val)[0]
    }

    Vue.prototype.TimeToDate = function (value) {
      const date = new Date(parseInt(value) * 1000)
      const y = date.getFullYear()
      let m = date.getMonth() + 1
      m = m < 10 ? `0${m}` : m
      let d = date.getDate()
      d = d < 10 ? `0${d}` : d
      let h = date.getHours()
      h = h < 10 ? `0${h}` : h
      let minute = date.getMinutes()
      let second = date.getSeconds()
      minute = minute < 10 ? `0${minute}` : minute
      second = second < 10 ? `0${second}` : second
      return `${y}-${m}-${d} ${h}:${minute}:${second}`
    }

    Vue.prototype.GetOrderStatus = function (val) {
      return this.$variable.orderStatus.filter(item => item.value === val)[0]
    }

    Vue.prototype.GetOrderWithdrawStatus = function (val) {
      return this.$variable.payStatus.filter(item => item.value === val)[0]
    }

    Vue.prototype.GetChannelType = function (val) {
      return this.$variable.channelType.filter(item => item.value === val)[0]
    }
  },

}
