<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >

    <component
      :is="layout"
    >

      <router-view />

    </component>

  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
// import { provideToast } from 'vue-toastification/composition'
// import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {

  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

  },

  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },

  // created() {
  //   if (this.isMobile()){
  //     this.showToast(200,"系统监测到您当前正在使用移动设备,开始自动切换到非标准设备模式")
  //     this.$store.state.appConfig.layout.type = "horizontal"
  //   }
  // },

  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },

  setup() {
    const { skin, skinClasses } = useAppConfig()

    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)

    return {
      skinClasses,
    }
  },
}
</script>
<style lang="scss">
body {
  font-family:”Microsoft YaHei”,Arial,Helvetica,sans-serif,”宋体”;
}
</style>
