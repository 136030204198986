import Vue from 'vue'
import config from '@/common/variable'
// axios
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: config.apiAddr,
  timeout: 2000000,
  headers: { Authorization: `${localStorage.getItem('token')}`, Uid: `${localStorage.getItem('uid')}` },
})

// Vue.prototype.$http = axiosIns

export default axiosIns
